<template>
  <v-dialog
    v-model="dialogVisible"
    width="400px"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        class="mb-2 mr-3"
        rounded
        v-on="on"
      >
        {{ $t('NewGroup') }}
      </v-btn>
    </template>
    <v-card
      max-height="300"
      flat
      class="rounded-xl"
    >
      <v-card-title>
        {{ $t('NewGroup') }}
      </v-card-title>

      <v-card-text class="pt-3">
        <v-form ref="validateGroupName">
          <v-text-field
            v-model="groupName"
            :label="$t('GroupName')"
            outlined
            dense
            maxlength="20"
            :rules="[validators.minLenInput, validators.maxLenInput, validators.required]"
            rounded
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            rounded
            color="primary"
            class="me-2"
            @click="addCardForGroup(groupName)"
          >
            {{ $t('Ok') }}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            outlined
            @click="closeDialog"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { maxLenInput, minLenInput, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'
import usePopupWithHash from '@/composables/usePopupWithHash'

export default {
  props: ['groups'],
  setup(props, { emit }) {
    const { isPopupOpen: dialogVisible } = usePopupWithHash('create-new-group')

    const groupName = ref()
    const validateGroupName = ref(null)

    const toast = useToast()
    const showToast = message => toast.error(i18n.t(message))

    const closeDialog = () => {
      dialogVisible.value = false
      groupName.value = ''
      validateGroupName.value.reset()
    }

    // Send POST request if a group name does not already exist
    const addCardForGroup = item => {
      if (validateGroupName.value.validate()) {
        dialogVisible.value = false
        if (!props.groups.some(el => el.name === item)) {
          store
            .dispatch('createNewGroup', { id: store.state.eventData.id, name: item })
            .then(() => {
              Vue.prototype.$gtag.event('Created_Group', {
                event_category: 'Group Management',
                event_label: 'Created group',
              })
              emit('fetch')
            })
            .catch(err => {
              if (err.response.data.message === 'reached_group_limit_per_event') {
                showToast(i18n.t('maxNumOfGroup'))
              } else {
                showToast(i18n.t('errorManageEvents'))
              }
            })
        } else {
          showToast(i18n.t('errorNewGroup'))
        }
      }
      groupName.value = ''
    }

    return {
      dialogVisible,
      groupName,
      addCardForGroup,
      closeDialog,
      validateGroupName,

      validators: {
        minLenInput,
        maxLenInput,
        required,
      },
    }
  },
}
</script>
