import { usePopupStore } from '@/store/popupStore'
import { computed, set } from '@vue/composition-api'

export default function usePopupWithHash(popupHashInput) {
  const popupStore = usePopupStore()
  const popupHash = popupHashInput.replace(/^#/, '')

  if (!(popupHash in popupStore.popups)) {
    set(popupStore.popups, popupHash, false)
  }

  const isPopupOpen = computed({
    get: () => popupStore.popups[popupHash],
    set: value => {
      popupStore.popups[popupHash] = value
    },
  })

  return {
    isPopupOpen,
  }
}
