<template>
  <!-- Loading circle -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <!-- Title card  -->
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
        order="4"
        order-md="3"
        class="align-self-end mb-sm-2 mt-2"
      >
        <v-card
          class="ps-3 rounded-xl"
          flat
        >
          <v-row
            align="start"
            justify="start"
          >
            <v-col
              cols="12"
              sm="10"
              class="py-0"
            >
              <v-card-title class="page-title flex-nowrap text-2xl mt-0 pt-2 px-1 align-center">
                <div class="d-flex align-center">
                  <v-avatar
                    size="40"
                    class="v-avatar-light-bg primary--text me-2"
                  >
                    <v-icon
                      size="25"
                      color="primary"
                    >
                      {{ icons.mdiAccountGroupOutline }}
                    </v-icon>
                  </v-avatar>
                  <span class="text-no-wrap font-weight-bold">{{ $t('ManageGroups') }}</span>
                </div>
              </v-card-title>

              <v-card-subtitle class=" flex-nowrap text-no-wrap px-13 pt-0 mt-n6 mb-2">
                {{ $t('messageForNumberOfGroups') }} {{ groups.length }} {{ $t('Groups') }}
              </v-card-subtitle>

              <v-card-text
                v-if="$vuetify.breakpoint.smAndUp"
                class="pa-0 text-center"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col
                    v-for="(advantage, index) in $t('AdvantageGroupsManagement')"
                    :key="index"
                    cols="12"
                    md="3"
                    sm="3"
                  >
                    <v-icon
                      size="25"
                      color="primary"
                    >
                      {{ advantageGroupsIcons[index] }}
                    </v-icon>

                    <div>
                      {{ advantage }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions
                v-if="!$vuetify.breakpoint.smAndUp"
                class="text-center"
              >
                <v-row>
                  <v-col
                    class="mt-0 pr-0"
                  >
                    <createNewGroup
                      :groups="groups"
                      @fetch="fetchGroups"
                    ></createNewGroup>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.smAndUp"
              cols="12"
              sm="12"
            >
              <div>
                <v-img
                  width="130"
                  src="@/assets/images/3d-characters/pose-fs-9.png"
                  class="gamification-john-pose-2"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Create new group -->
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col
        class="mt-0 pr-0"
      >
        <createNewGroup
          :groups="groups"
          @fetch="fetchGroups"
        ></createNewGroup>
      </v-col>
    </v-row>

    <!-- Groups card display -->
    <v-row v-if="groups">
      <v-col
        v-for="groupCard in groups"
        :key="groupCard.id"
        md="3"
        sm="4"
        cols="12"
        class="pt-4"
        align="center"
      >
        <v-card
          height="180px"
          class="rounded-xl"
          flat
          :loading="loading"
        >
          <!-- Edit group option: Edit/Delete -->
          <v-menu
            v-model="menuOpen[groupCard.id]"
            offset-x
            offset-y
            transition="scale-transition"
            origin="center center"
            top
            right
            close-on-click
          >
            <template
              v-slot:activator="{ on, attrs } "
            >
              <v-btn
                icon
                class="verticalDots black--text"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-card
              width="110"
            >
              <v-list
                class="ml-4"
              >
                <!-- Delete card -->
                <v-list-item
                  link
                >
                  <v-dialog
                    v-model="deleteQA"
                    width="400"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        icon
                        v-on="on"
                      >
                        <v-icon
                          size="20"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>

                        <span>{{ $t('Delete') }}</span>
                      </v-btn>
                    </template>
                    <v-card
                      height="100%"
                      class="rounded-xl text-center"
                      flat
                    >
                      <v-card-title
                        class="align-center justify-center mb-1"
                      >
                        <v-icon
                          color="primary"
                          right
                          size="40"
                        >
                          {{ icons.mdiAlertCircleOutline }}
                        </v-icon>
                      </v-card-title>
                      <v-card-subtitle
                        class="text-xl pb-0"
                        style="word-break: break-word"
                      >
                        {{ $t('messageBeforeDelete') }}
                      </v-card-subtitle>
                      <v-card-text
                        class="text-center"
                        style="word-break: break-word"
                      >
                        {{ groupCard.name }}
                      </v-card-text>

                      <v-card-actions
                        class="mt-6 px-0"
                      >
                        <v-flex>
                          <v-btn
                            color="primary"
                            rounded
                            class="me-3"
                            @click="delGroup(groupCard.id);"
                          >
                            {{ $t('Ok') }}
                          </v-btn>
                          <v-btn
                            color="primary"
                            outlined
                            rounded
                            @click="deleteQA = false"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>
                        </v-flex>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>

                <!-- Edit group name -->
                <v-list-item
                  link
                >
                  <v-dialog
                    v-model="EditGroupDialog"
                    width="400"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        icon
                        v-on="on"
                        @click="groupName = groupCard.name"
                      >
                        <v-icon
                          size="20"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>

                        <span> {{ $t('Edit') }}</span>
                      </v-btn>
                    </template>
                    <v-card
                      max-height="300"
                      class="rounded-xl"
                    >
                      <v-card-title>
                        {{ $t('EditGroupName') }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-col
                        cols="12"
                        md="12"
                        class="mx-auto"
                      >
                        <v-card-text>
                          <v-text-field
                            v-model="groupName"
                            :label="$t('GroupName')"
                            outlined
                            dense
                            rounded
                            maxlength="20"
                            class="pt-5"
                          ></v-text-field>
                        </v-card-text>
                      </v-col>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="12"
                          class="d-flex justify-center"
                        >
                          <v-btn
                            rounded
                            color="primary"
                            class="me-2"
                            @click="editGroupName(groupCard.id); EditGroupDialog = false; menuOpen[groupCard.id] = false"
                          >
                            {{ $t('Ok') }}
                          </v-btn>
                          <v-btn
                            color="primary"
                            rounded
                            outlined
                            @click="EditGroupDialog = false;menuOpen[groupCard.id] = false"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Group details -->
          <h2
            class="pt-7 align-center justify-center"
            style="word-break: break-word"
          >
            {{ groupCard.name }}
          </h2>

          <v-card-text
            justify="center"
            align="center"
            class="mt-1 pb-1"
          >
            {{ groupCard.guestGroupsCount }} {{ $t('NumberGroupMember') }}
          </v-card-text>
          <!-- Display 3 member of group -->
          <v-card-text
            v-if="(groupCard.guests.length > 0)"
            justify="center"
            align="center"
            class="mt-8 pb-1"
          >
            <v-row
              class="justify-center align-center"
            >
              <div
                v-for="group in groupCard.guests"
                :key="group.id"
                class="v-avatar-group"
                :class="rootThemeClasses"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar
                      size="40"
                      color="#C4B0FF"
                      class="white--text"
                      v-on="on"
                    >
                      {{ avatarText(group) }}
                    </v-avatar>
                  </template>
                  <span>{{ group }}</span>
                </v-tooltip>
              </div>
              <!-- Show the number of other group members (+4) -->
              <div
                class="v-avatar-group"
                :class="rootThemeClasses"
              >
                <v-avatar
                  v-show="groupCard.guestGroupsCount > 3 "
                  size="40"
                  color="primary"
                  class="white--text"
                >
                  <span> +{{ groupCard.guestGroupsCount - 3 }} </span>
                </v-avatar>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountGroupOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiGoogleAnalytics,
  mdiHeadLightbulbOutline,
  mdiMagnify,
  mdiOrderBoolDescendingVariant,
  mdiPencilOutline,
  mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import usePopupWithHash from '@/composables/usePopupWithHash'
import CreateNewGroup from './CreateNewGroup.vue'

export default {
  components: { CreateNewGroup },
  setup() {
    const { rootThemeClasses } = useVuetify()
    const { isPopupOpen: EditGroupDialog } = usePopupWithHash('edit-group')
    const { isPopupOpen: deleteQA } = usePopupWithHash('delete-group')

    const toast = useToast()
    const showToast = message => toast.error(i18n.t(message))
    const loading = ref(true)
    const groups = ref()
    const groupName = ref('')
    const menuOpen = ref([])

    const advantageGroupsIcons = [
      mdiOrderBoolDescendingVariant,
      mdiGoogleAnalytics,
      mdiMagnify,
      mdiHeadLightbulbOutline,
    ]

    const fetchGroups = () => {
      loading.value = true
      store.dispatch('fetchGroups', { id: store.state.eventData.id }).then(res => {
        groups.value = res.data
        loading.value = false
      })
    }

    fetchGroups()

    const editGroupName = id => {
      loading.value = true
      store
        .dispatch('editGroup', { id: store.state.eventData.id, groupID: id, name: groupName.value })
        .then(() => {
          fetchGroups()
        })
        .catch(error => {
          if (error.response.data.message === 'duplicate_names') {
            showToast(i18n.t('errorNewGroup'))
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const delGroup = id => {
      deleteQA.value = false
      loading.value = true
      store
        .dispatch('deleteGroup', { id: store.state.eventData.id, guestID: id })
        .then(() => {
          fetchGroups()
        })
        .catch(() => showToast(i18n.t('errorManageEvents')))
        .finally(() => {
          loading.value = false
        })
    }

    return {
      groups,
      avatarText,
      rootThemeClasses,
      delGroup,
      EditGroupDialog,
      groupName,
      editGroupName,
      deleteQA,
      loading,
      advantageGroupsIcons,
      fetchGroups,
      menuOpen,

      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAlertCircleOutline,
        mdiAccountGroupOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.verticalDots {
  position: absolute;
  right: 0px;
}

.gamification-john-pose-2 {
  position: absolute;
}

.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 2%;
  }
  @include rtl() {
    left: 2%;
  }
}
@media (max-width: 800px) {
  .gamification-john-pose-2 {
    max-width: 100px;
  }
}
@media (max-width: 600px) {
  .gamification-john-pose-2 {
    display: none;
  }
}

@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
